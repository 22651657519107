"use client";
import React, { createContext, useState, useEffect } from 'react';
import { getLoginUserDetails } from "helpers/utis";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [infoData, setInfoData] = useState(null);

    useEffect(() => {
        if (!infoData) {
            getLoginUserDetails().then(({ data }) => {
                setInfoData(data);
            })
        }
    }, []);

    return (
        <GlobalContext.Provider value={{ infoData }}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
