import React from "react";
import "./loader.scss"; 

const Loader = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
