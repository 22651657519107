import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import "./mainlayout.scss";
import { getIpAddress, getToken } from "helpers/utis";
import { logout } from "service/utilities";
import { Sidebar } from "components/common/Sidebar";
import AdminNavbar from "components/Navbar";
import { GlobalProvider } from "context/GlobalContext";

export function MainLayout(props) {
  const { children } = props;
  const [isOpen, setOpen] = useState(true);
  const toggle = () => setOpen(!isOpen);

  const websiteGuard = useCallback(() => {
    if (!getToken()) logout();
  }, []);

  useEffect(() => {
    websiteGuard();
  });

  useEffect(() => {
    if (!localStorage.getItem("ip")) {
      getIpAddress();
    }
  }, []);

  return (
    <GlobalProvider>
      <div className={`main-layout container-fluid`}>

        <Sidebar toggle={toggle} isOpen={isOpen} />

        <div className="main-layout-wrapper">
          <AdminNavbar isOpen={isOpen} />

          <div
            className={`main-content-wrapper ${isOpen ? "open-layout" : "closed-layout"}`}>
            {children}
          </div>
        </div>
      </div>
    </GlobalProvider>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
