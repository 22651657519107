import { useState, useEffect, useContext } from 'react'
import Loader from "components/common/loader";
import { getToken, checkPermission } from "helpers/utis";
import { currentLoginUserAct } from "redux/actions/staffsAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GlobalContext from 'context/GlobalContext';

const CheckPermission = ({ module, method, children }) => {
    const [hasPermission, setHasPermission] = useState(null);
    const { infoData } = useContext(GlobalContext);

    useEffect(() => {
        const checkPermissionsAsync = async () => {
            if (!infoData) return;

            try {
                const moduleNameArr = module.split('|');
                const resultArr = await Promise.all(
                    moduleNameArr.map(moduleName => checkPermission(moduleName, method, infoData))
                );

                if (resultArr.includes(true)) {
                    setHasPermission(true);
                } else {
                    setHasPermission(false);
                }
            } catch (error) {
                console.error('Error checking permission:', error);
                setHasPermission(false);
            }
        };

        if (infoData && module) {
            checkPermissionsAsync();
        }
    }, [infoData, module, method]);

    if (hasPermission === null) {
        return <Loader />;
    }

    return <>
        {hasPermission ? (
            children
        ) : (<>
        </>)}
    </>;
};

// const CheckPermission = ({ module, method, children, currentLoginUserApi }) => {
//     const [hasPermission, setHasPermission] = useState(null);
//     const [currentLoginDetails, setCurrentLoginDetails] = useState();
//     const { infoData } = useContext(GlobalContext);

//     console.log(infoData, 'infoData====')
//     if (infoData) {
//         setCurrentLoginDetails(infoData);
//     } else {
//         /*useEffect(() => {
//             currentLoginUserApi().then(({ data }) => {
//                 setCurrentLoginDetails(data);
//             }).catch(() => {
//                 console.log("error => failed to get admin details");
//             });
//         }, [getToken()]);*/

//         return <></>;
//     }

//     /*useEffect(() => {
//         currentLoginUserApi().then(({ data }) => {
//             setCurrentLoginDetails(data);
//         }).catch(() => {
//             console.log("error => failed to get admin details");
//         });
//     }, [getToken()]);*/

//     useEffect(() => {
//         const checkPermissionsAsync = async () => {
//             if (!currentLoginDetails) return;

//             try {
//                 const moduleNameArr = module.split('|');
//                 const resultArr = await Promise.all(
//                     moduleNameArr.map(moduleName => checkPermission(moduleName, method, currentLoginDetails))
//                 );

//                 if (resultArr.includes(true)) {
//                     setHasPermission(true);
//                 } else {
//                     setHasPermission(false);
//                 }

//                 // const result = await checkPermission(module, method, currentLoginDetails);
//                 // setHasPermission(result);
//             } catch (error) {
//                 console.error('Error checking permission:', error);
//                 setHasPermission(false);
//             }
//         };

//         if (currentLoginDetails && module) {
//             checkPermissionsAsync();
//         }
//     }, [currentLoginDetails, module, method]);

//     if (hasPermission === null) {
//         return <Loader />;
//     }

//     return <>
//         {hasPermission ? (
//             children
//         ) : (<>
//         </>)}
//     </>;
// };

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            currentLoginUserApi: currentLoginUserAct
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(CheckPermission);