import moment from "moment";
import cryptLib from "@skavinvarnan/cryptlib";

import { Toast } from "service/toast";
import axios from "axios";
import config from "../config";

export const dateFormatter = (date, format = "DD/MM/YYYY") => {
  if (!date) return "";

  const finalDate = moment(date).format(format);
  return finalDate === "Invalid date" ? "" : finalDate;
};

/**
 * Extract the right error message from react-hook-form error object
 *
 * @param {object} errors Errors object from react-hook-form
 * @param {object} fieldArrayError  { index, fieldName: "date", arrayName: "callLogs", }
 * @param {string} name
 */

export const getFormErrorMessage = (errors, fieldArrayError, name) => {
  if (!name || !errors) return null;

  if (!fieldArrayError && name) {
    return errors[name];
  }

  return (
    !!errors[fieldArrayError.arrayName] &&
    errors[fieldArrayError.arrayName][fieldArrayError.index] &&
    typeof errors[fieldArrayError.arrayName][fieldArrayError.index][
    fieldArrayError.fieldName
    ] !== "undefined" &&
    errors[fieldArrayError.arrayName][fieldArrayError.index][
    fieldArrayError.fieldName
    ]
  );
};

export const urlCombiner = (url) => {
  const protocol = ["h", "t", "t", "p"].join("");

  return `${protocol}://${url}`;
};

export const defaultValidator = (val, defaultValue) => {
  return val || defaultValue;
};

export const ToastError = (err) => {
  return Toast({ type: "error", message: err.Error || err.message || "Error" });
};

// add Query
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = [
    "page",
    "limit",
    "search",
    "status",
    "type",
    "fromDate",
    "toDate",
    "stateId",
    "cityId",
    "filter",
    "startDate",
  ];

  keys.forEach((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] !== "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).forEach((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

// generate Query
export const generateQuery = (query) => {
  let url = "";

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === "" ||
        query[key] == null ||
        key === "url_id" ||
        (query[key] !== null && query[key].toString().trim() === "")
      ) {
        return accumulator;
      }
      return `${accumulator}${index !== 0 ? "&" : "?"}${key}=${query[key]}`;
    }, "")
  );
};

//decrpyt data
export const decryptData = (data) => {
  try {
    return cryptLib.decryptCipherTextWithRandomIV(
      data,
      "asdkshEDBUFUN64H$$lkKR"
    );
  } catch {
    return data;
  }
};

//encrypt data
export const encryptData = (data) => {
  try {
    return cryptLib.encryptPlainTextWithRandomIV(
      data,
      "asdkshEDBUFUN64H$$lkKR"
    );
  } catch {
    return data;
  }
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("adminToken");
  }
};

export const ternaryCondition = (val1, val2 = "", defaultValue = "") => {
  return val1 ? val2 : defaultValue;
};

//get s.no
export const getSNo = (index, page = 1) => {
  return parseInt((page - 1) * 10) + index + 1;
};

export const getIpAddress = async () => {
  const data = await axios({ method: "get", url: "https://api.ipify.org" });
  let ip = data?.data;
  localStorage.setItem("ip", ip);
};

export const getLoginUserDetails = async () => {
  try {
    const token = getToken();
    const response = await axios.get(
      `${config.api.userService}/staff/loginStaffDetails`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const getIp = () => {
  return defaultValidator(localStorage.getItem("ip"), "");
};

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
]

export const isSuperAdmin = async (user) => {
  if (user?.roleFor && user?.roleFor === "Admin") {
    return true;
  } else {
    return false;
  }
}

export const checkPermission = async (moduleName, permissionMethod, currentLoginDetails) => {
  const permissionMethods = permissionMethod.split('|');

  const adminStatus = await isSuperAdmin(currentLoginDetails?.role_details);
  if (adminStatus) {
    return true;
  }

  try {
    const userRole = currentLoginDetails?.role_details;

    if (!userRole || userRole.status !== "Active") {
      return false;
    }

    if (!userRole.permissions || !permissionMethods) {
      return false;
    }

    let moduleData = JSON.parse(userRole.permissions);

    if (moduleData[moduleName]) {
      if (permissionMethods.length > 1) {
        for (const perm of permissionMethods) {
          if (moduleData[moduleName].includes(perm)) {
            return true;
          }
        }
        return false;
      } else {
        return moduleData[moduleName].includes(permissionMethod);
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error checking permission:', error);
    return false;
  }
};