import PropTypes from "prop-types";
import { useCallback, useMemo, useState, useEffect } from "react";
import { history } from "helpers";
import CheckPermission from "components/common/CheckPermission";

export function ListItem({ icon, image, title, subOptions, link, module }) {
  // const [isActiveItem, setIsActiveItem] = useState(false);
  // useEffect(() => {
  //   const pathName = history.location.pathname;
  //   setIsActiveItem(pathName === link);
  // }, [history.location.pathname,link]);
  const isActiveItem = useMemo(() => {
    const pathName = history?.location?.pathname;
    return pathName?.includes(link);
  }, [history.location.pathname, link]);

  const isSubActiveItem = useCallback((subLink) => {
    const pathName = history.location.pathname;
    return pathName === subLink;
  }, [history.location.pathname]);

  const containsSubOptions = useMemo(() => subOptions?.length > 0, [
    subOptions,
  ]);

  const [isOpen, setOpen] = useState(false);

  const handleClick = (routeLink, type) => {
    if (containsSubOptions && type === "main") return setOpen(!isOpen);

    return history.push(routeLink);
  };

  if (module) {
    return (<CheckPermission
      module={module}
      method={module == 'settings' ? 'edit' : 'view'}>
      <li className={`item ${isActiveItem ? "active-item" : ""}`}>
        <button
          className={`item ${containsSubOptions ? "sub-options" : ""}  ${isOpen ? "open-options" : ""}`}
          type="button"
          onClick={() => handleClick(link, "main")}>

          {icon && <i className={`icon-${icon}`} />}
          {image && <img src={image} alt={title} className="icon-image" />}
          <p className="item-label">{title}</p>
        </button>

        {subOptions && (
          <div
            className={`sub-options-container d-flex flex-column ${isOpen ? "show-options" : "hide-options"}`}>
            {subOptions?.map(({ label, subLink, subModule }) => (
              <CheckPermission
                module={subModule}
                method="view"
                key={subLink}>
                <button
                  className={`sub-option-item p-0 ${isSubActiveItem(subLink) ? "sub-item-active" : ""}`}
                  type="button"
                  onClick={() => handleClick(subLink, "sub")}>

                  <span className="legend" />
                  {label}

                </button>
              </CheckPermission>
            ))}
          </div>
        )}
      </li>
    </CheckPermission>);
  }

  return (
    <li className={`item ${isActiveItem ? "active-item" : ""}`}>
      <button
        className={`item ${containsSubOptions ? "sub-options" : ""}  ${isOpen ? "open-options" : ""
          }`}
        type="button"
        onClick={() => handleClick(link, "main")}
      >
        {icon && <i className={`icon-${icon}`} />}
        {image && <img src={image} alt={title} className="icon-image" />}
        <p className="item-label">{title}</p>
      </button>
      <div
        className={`sub-options-container d-flex flex-column ${isOpen ? "show-options" : "hide-options"
          }`}
      >
        {subOptions?.map(({ label, subLink }) => (
          <button
            className={`sub-option-item p-0 ${isSubActiveItem(subLink) ? "sub-item-active" : ""
              }`}
            type="button"
            key={subLink}
            onClick={() => handleClick(subLink, "sub")}
          >
            <span className="legend" />
            {label}
          </button>
        ))}
      </div>
    </li>
  );
}

ListItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  subOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
};

ListItem.defaultProps = {
  icon: "",
  title: "",
  link: "",
  subOptions: [],
};