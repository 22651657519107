import { StaffApi } from "service/apiVariables";
import { addQuery } from "helpers/utis";

export const staffAct =
  (body) =>
    (dispatch, getStaffs, { api, Toast }) => {
      addQuery(body, StaffApi.staffApi);
      return new Promise((resolve, reject) => {
        api({ ...StaffApi.staffApi, body })
          .then((data) => {
            Toast({ type: "success", message: data.message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const staffDetailsAct =
  (staffId) =>
    (dispatch, getStaffs, { api, Toast }) => {
      StaffApi.staffDetailApi.staffId = staffId;
      return new Promise((resolve, reject) => {
        api({ ...StaffApi.staffDetailApi })
          .then((data) => resolve(data))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const createStaffAct =
  (body) =>
    (dispatch, getStaffs, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...StaffApi.createStaffApi, body })
          .then((data) => {
            Toast({ type: "success", message: data.message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const updateStaffAct =
  (body, id) =>
    (dispatch, getStaffs, { api, Toast }) => {
      StaffApi.updateStaffApi.id = id;
      return new Promise((resolve, reject) => {
        api({ ...StaffApi.updateStaffApi, body })
          .then((data) => {
            Toast({ type: "success", message: data.message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const deleteStaffAct =
  (id) =>
    (dispatch, getStaffs, { api, Toast }) => {
      StaffApi.deleteStaffApi.id = id;
      return new Promise((resolve, reject) => {
        api({ ...StaffApi.deleteStaffApi })
          .then((data) => {
            Toast({ type: "success", message: data.message });
            resolve(data);
          })
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };

export const currentLoginUserAct =
  (staffId) =>
    (dispatch, getStaffs, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...StaffApi.loginStaffDetailApi })
          .then((data) => resolve(data))
          .catch(({ message }) => reject(Toast({ type: "error", message })));
      });
    };